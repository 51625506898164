import type { Composer } from 'vue-i18n'
import { useTranslations } from '~/composables/useTranslations'

export default defineNuxtRouteMiddleware(async (to) => {
  // const nuxtApp = useNuxtApp()
  // const { getTranslations } = useTranslations()
  //
  // const i18n = (nuxtApp.$i18n as Composer)
  //
  // if (i18n) {
  //   const routeName = to.name as string
  //
  //   if (routeName) {
  //     const locale = routeName.substring(routeName.indexOf('___') + 3)
  //     const translations = await getTranslations(locale, to)
  //     if (Object.keys(translations).length) {
  //       i18n.mergeLocaleMessage(locale, translations)
  //     }
  //   }
  // }
})
