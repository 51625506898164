import { default as indexGFi23Lq432Meta } from "/builds/neeo33/portfolio/src/frontend/pages/index.vue?macro=true";
import { default as projectseIPoBXb2HcMeta } from "/builds/neeo33/portfolio/src/frontend/pages/projects.vue?macro=true";
export default [
  {
    name: "index___en",
    path: "/",
    meta: indexGFi23Lq432Meta || {},
    component: () => import("/builds/neeo33/portfolio/src/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___lv",
    path: "/lv/",
    meta: indexGFi23Lq432Meta || {},
    component: () => import("/builds/neeo33/portfolio/src/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "projects___en",
    path: "/projects/",
    meta: projectseIPoBXb2HcMeta || {},
    component: () => import("/builds/neeo33/portfolio/src/frontend/pages/projects.vue").then(m => m.default || m)
  },
  {
    name: "projects___lv",
    path: "/lv/projekti/",
    meta: projectseIPoBXb2HcMeta || {},
    component: () => import("/builds/neeo33/portfolio/src/frontend/pages/projects.vue").then(m => m.default || m)
  }
]