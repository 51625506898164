<script setup lang="ts">
const { locale } = useI18n()

useHead({
  htmlAttrs: {
    lang: locale.value
  }
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
a {
  @apply no-underline;
}

h1, h2 {
  @apply text-[2.125rem] md:text-[3.125rem] leading-[1.2] font-bold;
}

h3 {
  @apply text-[1.875rem] leading-[1.2] font-bold;
}

:root {
  font-family: 'Solomon Sans', sans-serif;
}

html {
  @apply font-SolomonSans scroll-smooth;
}

body {
  @apply bg-black;
}

.container {
  @apply max-w-[93.75rem] px-5 mx-auto w-full;
}
</style>
